import { systemInfo } from "../libs/utils";
import config from "../config";

const ADMIN_FUNCTION_CREATE_USERS           = 1;
const ADMIN_FUNCTION_UPDATE_USER_STATE      = 2;
const ADMIN_FUNCTION_UPDATE_USER_TYPE       = 3;
const ADMIN_FUNCTION_ADD_PROFILE            = 4;
const ADMIN_FUNCTION_GET_PROFILE_LIST       = 5;
const ADMIN_FUNCTION_UPDATE_PROFILE         = 6;
const ADMIN_FUNCTION_DELETE_PROFILE         = 7;
const ADMIN_FUNCTION_GET_CERT_ACTIVITY      = 8;
const ADMIN_FUNCTION_GET_AUDIT_LOG          = 9;
const ADMIN_FUNCTION_ADD_PRODUCT            = 11;
const ADMIN_FUNCTION_GET_PRODUCT_LIST       = 12;
const ADMIN_FUNCTION_UPDATE_PRODUCT         = 13;
const ADMIN_FUNCTION_DELETE_PRODUCT         = 14;
const ADMIN_FUNCTION_GET_SIGN_ACTIVITY      = 15;

// Enrollment functions for enrolling clients
const ENROLL_FUNCTION_REQUEST_CLIENT_ENROLLMENT_CODE = 1;
const ENROLL_FUNCTION_GET_CLIENT_ENROLLMENT_LIST     = 2;
const ENROLL_FUNCTION_DELETE_CLIENT_ENROLLMENT       = 3;
const ENROLL_FUNCTION_UPDATE_CLIENT_ENROLLMENT       = 4;

// Enrollment functions for AA to DLM enrollment
const ENROLL_FUNCTION_ADD_DLM_ENROLLMENT = 100;
const ENROLL_FUNCTION_VERIFY_DLM_ENROLLMENT = 101;

export async function requestToken(code) {

  const redirectStr = config.AUTH_DIAG_ENVIRONMENT === 'DEV' ? 'http://localhost:4000/Home' : "https://" + window.location.hostname + "/Home";

  const bodyStr =
    "grant_type=authorization_code&"+
    "code="+code+"&"+
    "client_id="+config.cognito.APP_CLIENT_ID+"&"+
    "redirect_uri="+redirectStr+"&"+
    "scope=openid";

  return await fetch(config.cognito.DOMAIN+"/oauth2/token",
    { method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Length': bodyStr.length,
      },
      body: bodyStr,
    }).then((result) => {
      return result.json();
    }).then((response) => {
      if (response.message) {
        return response.message;
      } else {
       localStorage.setItem('Id-Token', response.id_token);
       return null;
      }
    }).catch((err) => {
      console.log("err: ", err);
      return err;
    });
}

export async function getUserRecord() {
  const idToken = localStorage.getItem("Id-Token");
  return await fetch((config.apiGatewayGetUser?.URL || config.apiGateway.URL) + "/ebui/getUserInfo",
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.errorDesc) {
      if (response.errorDesc === "User record was not found!") {

      }
      return {error: response.errorDesc};
    } else {
      return response;
    }
  });
}

export async function updateUserState(userId, newState) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_USER_STATE,
    userID: userId,
    userState: newState,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }).then( result => {
      return result.json();
    }).then(response => {
      if (response.message) {
        return response.message;
      } else {
        return null;
      }
    });
}

function updateUserType(userId, type) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_USER_TYPE,
    userID: userId,
    userType: type,
  });

  return fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }).then( result => {
      return result.json();
    }).then(response => {
      if (response.errorDesc) {
        return { error: response.errorDesc };
      } else {
        return null;
      }
    });
}

export function getToolTypes() {
  const idToken = localStorage.getItem("Id-Token");
  return fetch(config.apiGateway.URL + "/ebui/getManufacturerList",
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      },
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  });

}

export async function getSystemConfig() {
  const idToken = localStorage.getItem("Id-Token");
  return await fetch(config.apiGateway.URL + "/ebui/getUIConfigs",
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      },
     }
  ).then(result => { return result.json(); }
  ).then((response) => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  }).catch((err) => {
    return err;
  });
}

export async function createUser(username, password, firstName, lastName, email, recaptcha, inviteCode) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    userName: username,
    password: password,
    firstName: firstName,
    lastName: lastName,
    email: email,
    recaptcha: recaptcha,
    invitationCode: inviteCode
  });
  return await fetch(config.apiGateway.URL + "/ebui/createUser",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then((response) => {
    if (response.message) {
      return response.message;
    } else {
      return null;
    }
  }).catch((err) => {
    return err;
  });
}

export async function updateUser(userID, authProvider, firstName, lastName, email, type) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    userID: userID,
    firstName: firstName,
    lastName: lastName,
    email: email,
  });
  if (authProvider === "COGNITO") {
    let response = await fetch(config.apiGateway.URL + "/ebui/updateUserInfo",
      { method: "post",
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': bodyStr.length,
          'Authorization': idToken
        },
        body: bodyStr,
      }
    ).then(result => {
      return result.json();
    }).then(response => {
      if (response.errorDesc) {
        return {error: response.errorDesc};
      } else {
        return response;
      }
    });
    if (response.error) {
      return { error: response.error };
    } else {
      return response;
    }
  }
  if (type) {
    let response = await updateUserType(userID, type);
    if (response && response.errorDesc) {
        return { error: response.errorDesc };
    }
  }
}

export function getTools() {
  const idToken = localStorage.getItem("Id-Token");
  return fetch(config.apiGateway.URL + "/ebui/getRegisteredToolList",
   { method: "post",
     headers: {
       'Content-Type': 'application/json',
       'Content-Length': 0,
       'Authorization': idToken
     },
   }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  });
}

export function addTool(serialNum, manufacturer, model) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    toolSerial: serialNum,
    toolManufacturerUUID: manufacturer,
    toolModelUUID: model
  });
  return fetch(config.apiGateway.URL + "/ebui/registerTool",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.errorDesc) {
      return {error: response.errorDesc};
    } else {
      return response;
    }
  });
}


export function removeTool(toolId) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    toolID: toolId
  });
  return fetch(config.apiGateway.URL + "/ebui/removeTool",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return null;
    }
  });
}


export async function findUser(username) {
  const idToken = localStorage.getItem("Id-Token");
  const userRec = await fetch(config.apiGateway.URL + "/ebui/findUser?userName="+username,
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => { return result.json(); }
  ).then((response) => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  }).catch((err) => {
    return err;
  });

  return ({firstName: userRec.firstName,
           lastName: userRec.lastName,
           userId: userRec.userID,
           username: username});
}

export async function getUsers() {
  const idToken = localStorage.getItem("Id-Token");
  let res = await fetch(config.apiGateway.URL + "/ebui/getUserList",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => { return result.json(); }
  ).then(response => {
    return response.userList;
  }).catch(error => {
    console.log("getUsers error: ", JSON.stringify(error));
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });

  if (res.error) {
    console.log("Error trying to record user session: ", res.error);
  }
  return res;
}

export async function recordUserSession() {
  const idToken = localStorage.getItem("Id-Token");
  const sysInfo = systemInfo();
  const bodyStr = JSON.stringify({
    browserName: sysInfo.browserName,
    browserVersion: sysInfo.browserVersion,
    hostOS: sysInfo.hostOS
  });

  return await fetch(config.apiGateway.URL + "/ebui/recordUserSession",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return null;
    }
  });
}

export async function addUsers(users) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_CREATE_USERS,
    userList: users,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function addProfile(profileID, profileLabel) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_ADD_PROFILE,
    profileID: profileID,
    profileLabel
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getProfiles() {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_GET_PROFILE_LIST,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function updateProfile(profileID, profileLabel, profileState) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_PROFILE,
    profileID: profileID,
    profileLabel: profileLabel,
    profileState: profileState
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function deleteProfile(profileID) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_DELETE_PROFILE,
    profileID: profileID
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getCertActivity(startDate, endDate) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_GET_CERT_ACTIVITY,
    startDate: startDate,
    endDate: endDate
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getSignActivity(startDate, endDate) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_GET_SIGN_ACTIVITY,
    startDate: startDate,
    endDate: endDate
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getAuditLog(startDate, endDate) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_GET_AUDIT_LOG,
    startDate: startDate,
    endDate: endDate
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getClientEnrollmentCode(label, enrollmentType) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    enrollFunctionID: ENROLL_FUNCTION_REQUEST_CLIENT_ENROLLMENT_CODE,
    label: label,
    enrollmentType: enrollmentType
  });
  return await fetch(config.apiGateway.URL + "/ebui/execEnrollmentFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getClientEnrollments() {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    enrollFunctionID: ENROLL_FUNCTION_GET_CLIENT_ENROLLMENT_LIST
  });
  return await fetch(config.apiGateway.URL + "/ebui/execEnrollmentFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function updateClientEnrollment(enrollmentCode, clientState, clientLabel) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    enrollFunctionID: ENROLL_FUNCTION_UPDATE_CLIENT_ENROLLMENT,
    enrollmentCode: enrollmentCode,
    clientState: clientState,
    clientLabel: clientLabel
  });
  return await fetch(config.apiGateway.URL + "/ebui/execEnrollmentFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function deleteClientEnrollment(enrollmentCode) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    enrollFunctionID: ENROLL_FUNCTION_DELETE_CLIENT_ENROLLMENT,
    enrollmentCode: enrollmentCode
  });
  return await fetch(config.apiGateway.URL + "/ebui/execEnrollmentFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function addDLMEnrollment(enrollmentCode) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    enrollFunctionID: ENROLL_FUNCTION_ADD_DLM_ENROLLMENT,
    enrollmentCode: enrollmentCode
  });
  return await fetch(config.apiGateway.URL + "/ebui/execEnrollmentFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function verifyDLMEnrollment(enrollmentCode) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    enrollFunctionID: ENROLL_FUNCTION_VERIFY_DLM_ENROLLMENT,
    enrollmentCode: enrollmentCode
  });
  return await fetch(config.apiGateway.URL + "/ebui/execEnrollmentFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => {
    return result.json()
  }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      console.log(error);
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function addProduct(productID, productLabel) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_ADD_PRODUCT,
    productID: productID,
    productLabel
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getProducts() {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_GET_PRODUCT_LIST,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function updateProduct(productID, productLabel, productState) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_PRODUCT,
    productID: productID,
    productLabel: productLabel,
    productState: productState
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function deleteProduct(productID) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_DELETE_PRODUCT,
    productID: productID
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}


